<!-- 高德地图 -->
<template>
    <div id="container"></div>
  </template>
  
  <script >
  import AMapLoader from "@amap/amap-jsapi-loader";
  window._AMapSecurityConfig = {
        securityJsCode: "56f21d0b10d59d272acc609098c30fbf",
      };
  export default{
    props:{
      mapData: {
        //中心点
        type: Object,
        required: false,
        default: ()=>{},
      },
    type: {
      type: String,
      default: "",
    },
    height:{
      type: String,
      default: "calc(100vh - 50px)",
    },
    path: {
      type: Array,
      default: () => [],
    },
    name: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
    deviceInfoList: {
      type: Array,
      required: false,
      default: []
    },
  },
    data(){
        return{
            map:null,
            markers:null,
            infoWindow:null,
            switchList:[
        require("@/assets/index/open.png"),
        require("@/assets/index/close.png"),
      ],
      markersList:[
       require("@/assets/index/point1.png"),
       require("@/assets/index/czpost1.png"),
       require("@/assets/index/czpost2.png"),
       require("@/assets/index/czpost3.png"),
       require("@/assets/index/czpost4.png"),
       require("@/assets/index/czpost5.png")
    ],
    groupByValue: []
        }
    },
 


  mounted() {
    this.mapInit();
  },
  destroyed() {
        //this.map.destroy();
        this.map = null
  },
  methods:{
     // 地图初始化
   mapInit () {
    AMapLoader.reset()  // 关键点-----解决catch里高德报错 (禁止多种API加载方式混用)
    AMapLoader.load({
      key: "23d95d6620fa5bd2e8da3f61be5de237",
      version: "2.0",
      plugins: [
        "AMap.Scale",
        "AMap.MouseTool",
        "AMap.Marker",
        "AMap.ContextMenu",
        "AMap.Polygon"
      ],
    })
      .then((AMap) => {
        this.map = new AMap.Map("container", {
         resizeEnable: true,
          viewMode: "3D",
          zoomEnable: true,
          dragEnable: true, 
          doubleClickZoom: true, 
          zoom: 10,
          layers: [new AMap.TileLayer.Satellite()],
          //center: [109.205343,34.639516]
          center: this.mapData.centerPosition.split(",")
        });
        this.setMapMarker()
        this.map.addControl(new AMap.Scale())
        let that = this;
        // 创建行政区查询对象
        AMap.plugin(['AMap.ToolBar','AMap.Scale','AMap.HawkEye','AMap.MapType','AMap.ControlBar','AMap.DistrictSearch'],function(){
          var district = new AMap.DistrictSearch({
              // 返回行政区边界坐标等具体信息
              extensions: 'all',
              // 设置查询行政区级别为 区 
              level: 'district'
          })

          district.search(this.mapData.areaName, function(status, result) {
              var bounds = result.districtList[0].boundaries
              var polygons = []
              if (bounds) {
              for (var i = 0, l = bounds.length; i < l; i++) {
                //生成行政区划polygon
                var polygon = new AMap.Polygon({
                    map: that.map,
                    strokeWeight: 5,
                    path: bounds[i],
                    fillOpacity: 0,
                    //fillColor: '#2F8AB3',
                    strokeColor: '#00d9ff'
                })
                polygons.push(polygon)

              }
              // 地图自适应
              // that.map.setFitView()
              }
          })
        })

     
      
        // this.viewCoordinate()

        }).catch(e => {
                //console.log(e);
            })
      },
      setMapMarker(){
        // const groupByValue = this.deviceInfoList.reduce((acc, obj) => {
        //   const key = obj.deviceType;
        //   if (acc.filter(v=>v.deviceType==key).length==0) acc.push(obj)
        //   return acc;
        // }, []);
        const makerList = []
        console.log('deviceInfoList', this.deviceInfoList)
        this.deviceInfoList.forEach(v=>{
          const lat = v.centerPos? v.centerPos.split(",") : ''
          console.log(lat)
          if (lat && lat.length>0) {
            const marker = new AMap.Marker({
              text:"name",
              position: new AMap.LngLat(lat[0],lat[1]),
              offset: new AMap.Pixel(-10, -10),
              icon: require("@/assets/index/czpost1-min.png"),
              zooms: [5,18]
            });
            marker.setLabel({
              content:v.baseName,
              direction:top,
            })
            marker.on("click", ()=>{
              this.$emit('onMarkerChange', v.id)
            });
            makerList.push(marker)
          }
         
        })
        this.map.add(makerList);
        // this.map.setFitView()
      },
     viewCoordinate () {
      //console.log(this.path,'======this.path')
        if (this.path.length > 0) {
          try {
            this.path.map((item) => {
              let lengths = item.centerPos.length;
              let centerPos = item.centerPos.substring(1, lengths-1);
              //console.log(centerPos)
            // if(item.deviceLocation && item.deviceLocation.includes(',')) { 
            if(centerPos && centerPos.includes(',')) {
            let icon = this.markersList[0];
            // if(item.deviceType==='1'){
            //     icon=this.markersList[3]
            // }else if(item.deviceType==='2'){
            //     icon=this.markersList[1]
            // }else if(item.deviceType==='3'){
            //     icon=this.markersList[4]
            // }else if(item.deviceType==='5'){
            //     icon=this.markersList[2]
            // }else if(item.deviceType==='6'){
            //     icon=this.markersList[0]
            // }
            const lat = centerPos.split(',')
            const markerPosition = new AMap.LngLat(lat[0], lat[1]);
            this.markers = new AMap.Marker({
              map: this.map,
              position: markerPosition,
              strokeColor: "#FFEB16",
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeStyle: "solid",
              icon: icon,
              // size: new AMap.Size(30, 10),
              offset: new AMap.Pixel(-6, -4),
              title:item.type
            });
           }
          });
          this.markers.on("click", this.handlerPath);
          } catch (error) {
            
          }
          
         
        }
      },
      handlerPath(e){
        const { target } = e;
        const { lng, lat } = target.getPosition();
        //console.log(target.getPosition(),'======获取路径',target.getOptions())
      }    
    }
  
}

  </script>
  <style lang="less">
  #container{width: 100%;height: 100%;}
  .custom-content-marker {
            position: relative;
            width: 1px;
            height: 1px;
        }

        .custom-content-marker img {
            width: 1px;
            height: 1px;
        }
  .amap-marker-label{
    color: #ffffff;
    border:1px solid #fff;
    font-weight: bold;
    background-color: rgb(2, 156, 252);


  }

  </style>
  